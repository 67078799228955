// User
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

// Domains
export const FETCH_DOMAIN_REQUEST = 'FETCH_DOMAIN_REQUEST';
export const FETCH_DOMAIN_SUCCESS = 'FETCH_DOMAIN_SUCCESS';
export const FETCH_DOMAIN_FAILURE = 'FETCH_DOMAIN_FAILURE';

export const FETCH_DOMAINS_REQUEST = 'FETCH_DOMAINS_REQUEST';
export const FETCH_DOMAINS_SUCCESS = 'FETCH_DOMAINS_SUCCESS';
export const FETCH_DOMAINS_FAILURE = 'FETCH_DOMAINS_FAILURE';

export const LOCK_DOMAIN_REQUEST = 'LOCK_DOMAIN_REQUEST';
export const LOCK_DOMAIN_SUCCESS = 'LOCK_DOMAIN_SUCCESS';
export const LOCK_DOMAIN_FAILURE = 'LOCK_DOMAIN_FAILURE';

export const UNLOCK_DOMAIN_REQUEST = 'UNLOCK_DOMAIN_REQUEST';
export const UNLOCK_DOMAIN_SUCCESS = 'UNLOCK_DOMAIN_SUCCESS';
export const UNLOCK_DOMAIN_FAILURE = 'UNLOCK_DOMAIN_FAILURE';

export const FETCH_DOMAIN_REGISTRANT_UPDATE = 'FETCH_DOMAIN_REGISTRANT_UPDATE';
export const FETCH_DOMAIN_REGISTRANT_UPDATE_SUCCESS = 'FETCH_DOMAIN_REGISTRANT_UPDATE_SUCCESS';
export const FETCH_DOMAIN_REGISTRANT_UPDATE_FAILED = 'FETCH_DOMAIN_REGISTRANT_UPDATE_FAILED';

export const RESPOND_REGISTRANT_CHANGE_REQUEST = 'RESPOND_REGISTRANT_CHANGE_REQUEST';
export const RESPOND_REGISTRANT_CHANGE_SUCCESS = 'RESPOND_REGISTRANT_CHANGE_SUCCESS';
export const RESPOND_REGISTRANT_CHANGE_FAILED = 'RESPOND_REGISTRANT_CHANGE_FAILED';

// Contacts
export const FETCH_CONTACT_REQUEST = 'FETCH_CONTACT_REQUEST';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_FAILURE = 'FETCH_CONTACT_FAILURE';

export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE';

// Companies
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

// Filter
export const SET_TECH = 'SET_TECH';
export const GET_TECH = 'GET_TECH';

export const DO_UPDATE_CONTACTS_REQUEST = 'DO_UPDATE_CONTACTS_REQUEST';
export const DO_UPDATE_CONTACTS_SUCCESS = 'DO_UPDATE_CONTACTS_SUCCESS';
export const DO_UPDATE_CONTACTS_FAILURE = 'DO_UPDATE_CONTACTS_FAILURE';
export const UPDATE_CONTACTS_REQUEST = 'UPDATE_CONTACTS_REQUEST';
export const UPDATE_CONTACTS_SUCCESS = 'UPDATE_CONTACTS_SUCCESS';
export const UPDATE_CONTACTS_FAILURE = 'UPDATE_CONTACTS_FAILURE';
